import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';
import legacyId from 'pages/redirection/legacy/@@global/id';

import messages from '../personal-data.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  label: messages.pages.profile.personalData.navigationMenu.label,
  link: () => link[id](),
  visibility$: authorization.getAuthorization$(id),
  parent: legacyId.profile,
  weight: 3,
};

export default menu;
