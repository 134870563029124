import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../forget-data'));

const routes: Route[] = [
  {
    name: id,
    path: '/:lang?/settings/forget-data/:type(approve|decline)/:hash',
    element: <AsyncPage />,
  },
];

export default routes;
