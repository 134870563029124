import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../country-ban'));
const AsyncSkeletonPage = React.lazy(() => import('../country-ban.skeleton'));

const routes: Route = {
  parent: parentId,
  name: id,
  path: 'country-ban',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
