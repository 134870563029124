import { of, map, combineLatest, defer } from 'rxjs';
import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import user from 'store/user';
import account from 'store/account';
import application from 'services/application';
import settings from 'configurations/application';
import type { NavigationMenuItem } from 'contracts';
import { IconList } from 'components/icon';
import happen from 'utils/happen';
import parse from 'utils/parse';
import is from 'utils/is';
import date from 'utils/date';
import unreadCounter from 'store/unread-counter';
import performerProfilePictures from 'store/performer-profile-pictures';
import performer from 'store/performer';

import messages from '../legacy.i18n';

const menu: NavigationMenuItem[] = [
  {
    name: 'dashboard',
    label: messages.legacy.pages.dashboard.navigationMenu.label,
    icon: { active: IconList.homeSolid, inactive: IconList.homeOutline },
    link: () => [link.dashboard({ alias: 'dashboard' }), link.dashboard()],
    weight: 1,
    visibility$: authorization.getAuthorization$('dashboard'),
  },
  {
    name: 'models',
    label: application.Oranum
      ? messages.legacy.pages.broadcasters.navigationMenu.label
      : messages.legacy.pages.models.navigationMenu.label,
    icon: { active: IconList.homeSolid, inactive: IconList.homeOutline },
    link: () => link.models(),
    weight: 1,
    visibility$: authorization.getAuthorization$('models')!.pipe(map((page) => page && application.LiveJasmin)),
  },
  {
    name: 'broadcasters',
    label: messages.legacy.pages.broadcasters.navigationMenu.label,
    icon: { active: IconList.groupTwoSolid, inactive: IconList.groupTwoOutline },
    link: () => link.models(),
    weight: 3,
    visibility$: authorization.getAuthorization$('models')!.pipe(map((page) => page && application.Oranum)),
  },
  {
    name: 'statisticsStudio',
    label: messages.legacy.pages.statisticsStudio.navigationMenu.label,
    icon: { active: IconList.chartLinesSolid, inactive: IconList.chartLinesOutline },
    link: () => link.statisticsStudio(),
    weight: 5,
    visibility$: authorization.getAuthorization$('statisticsStudio'),
  },
  {
    name: 'messengerSupport',
    label: messages.legacy.pages.messages.navigationMenu.label,
    icon: { active: IconList.envelopeSolid, inactive: IconList.envelopeOutline },
    link: () => link.messengerSupport({ page: 'inbox' }),
    weight: 7,
    visibility$: authorization
      .getAuthorization$('messengerSupport')!
      .pipe(map((allowed) => (is.nullish(allowed) ? allowed : allowed && user.isStudioView()))),
  },
  {
    name: 'statistics',
    label: messages.legacy.pages.statistics.navigationMenu.label,
    icon: { active: IconList.chartLinesSolid, inactive: IconList.chartLinesOutline },
    weight: 5,
    visibility$: undefined,
    children: [
      {
        name: 'fanClub',
        label: messages.legacy.pages.statistics.fanClub.navigationMenu.label,
        link: () => link.statisticsFanClub(),
        visibility$: authorization.getAuthorization$('statisticsFanClub'),
        weight: 4,
      },
    ],
  },
  {
    name: 'profile',
    label: messages.legacy.pages.profile.navigationMenu.label,
    icon: { active: IconList.profileSolid, inactive: IconList.profileOutline },
    weight: 7,
    visibility$: undefined,
    notification$: defer(() =>
      combineLatest([performerProfilePictures.onChange$, performer.onStatusChange$]).pipe(
        map(() => {
          return (performerProfilePictures.hasPictures() &&
            !performerProfilePictures.hasSelectedPicturePortraitCrop()) ||
            (!performerProfilePictures.hasPictures() && !performer.hasClosedStatus()) ||
            (application.LiveJasmin && performer.hasNudeCategory())
            ? -1
            : 0;
        })
      )
    ),
    children: [
      {
        name: 'profilePhoto',
        label: messages.legacy.pages.profile.profilePhoto.navigationMenu.label,
        link: () => link.profile({ page: 'profile-picture' }),
        visibility$: authorization.getAuthorization$('profile'),
        notification$: defer(() =>
          combineLatest([performerProfilePictures.onChange$, performer.onStatusChange$]).pipe(
            map(() => {
              return (performerProfilePictures.hasPictures() &&
                !performerProfilePictures.hasSelectedPicturePortraitCrop()) ||
                (!performerProfilePictures.hasPictures() && !performer.hasClosedStatus())
                ? -1
                : 0;
            })
          )
        ),
        weight: 1,
      },
      {
        name: 'accountData',
        label: messages.legacy.pages.profile.accountData.navigationMenu.label,
        link: () => link.profile({ page: 'introduction' }),
        visibility$: authorization.getAuthorization$('profile', { page: 'introduction' }),
        weight: 2,
      },
      {
        name: 'prices',
        label: messages.legacy.pages.profile.prices.navigationMenu.label,
        link: () => link.profile({ page: 'prices' }),
        visibility$: authorization.getAuthorization$('profile', { page: 'prices' }),
        weight: 4,
      },
    ],
  },
  {
    name: 'myContent',
    label: messages.legacy.pages.myContent.navigationMenu.label,
    icon: { active: IconList.myContentSolid, inactive: IconList.myContentOutline },
    weight: 9,
    visibility$: undefined,
    notification$: defer(() =>
      combineLatest([
        authorization
          .getAuthorization$('myContentEditor', { type: 'free' })!
          .pipe(map((page) => page && application.LiveJasmin)),
        authorization
          .getAuthorization$('myContentEditor', { type: 'premium' })!
          .pipe(map((page) => page && application.LiveJasmin)),
      ]).pipe(map((access) => (access.some(Boolean) ? -1 : 0)))
    ),
    children: [
      {
        name: 'freeContent',
        label: messages.legacy.pages.myContent.freeContent.navigationMenu.label,
        link: () => link.myContentEditor({ type: 'free' }),
        notification$: of(application.Oranum ? 0 : -1),
        visibility$: authorization.getAuthorization$('myContentEditor', { type: 'free' }),
        weight: 1,
      },
      {
        name: 'premiumContent',
        label: messages.legacy.pages.myContent.premiumContent.navigationMenu.label,
        link: () => link.myContentEditor({ type: 'premium' }),
        notification$: of(application.Oranum ? 0 : -1),
        visibility$: authorization.getAuthorization$('myContentEditor', { type: 'premium' }),
        weight: 2,
      },
      {
        name: 'payingSnapshots',
        label: messages.legacy.pages.myContent.payingSnapshots.navigationMenu.label,
        link: () => link.myContent({ page: 'paying-snapshot' }),
        visibility$: authorization.getAuthorization$('myContent', { page: 'paying-snapshot' }),
        weight: 5,
      },
    ],
  },
  {
    name: 'messages',
    label: messages.legacy.pages.messages.navigationMenu.label,
    icon: { active: IconList.envelopeSolid, inactive: IconList.envelopeOutline },
    weight: 11,
    notification$: defer(() =>
      combineLatest([
        unreadCounter.onMessagesMemberChange$,
        unreadCounter.onMessagesSupportChange$,
        unreadCounter.onMessagesSystemChange$,
      ]).pipe(map((access) => (access.some(Boolean) ? -1 : 0)))
    ),
    visibility$: combineLatest([
      authorization.getAuthorization$('messenger')!,
      authorization.getAuthorization$('messengerAutomatedMessages')!,
      authorization.getAuthorization$('messengerSupport')!,
      authorization.getAuthorization$('messengerSystem')!,
    ]).pipe(map((access) => user.isModelView() && access.some(Boolean))),
    children: [
      {
        name: 'member',
        label: messages.legacy.pages.messages.member.navigationMenu.label,
        link: () => link.messenger({ page: 'current-chats' }),
        visibility$: authorization.getAuthorization$('messenger'),
        notification$: defer(() => unreadCounter.onMessagesMemberChange$),
      },
      {
        name: 'messengerAutomatedMessages',
        label: messages.legacy.pages.messages.automatedMessages.navigationMenu.label,
        link: () => link.messengerAutomatedMessages(),
        visibility$: authorization.getAuthorization$('messengerAutomatedMessages'),
      },
      {
        name: 'messengerSupport',
        label: messages.legacy.pages.messages.messengerSupport.navigationMenu.label,
        link: () => link.messengerSupport({ page: 'inbox' }),
        visibility$: authorization.getAuthorization$('messengerSupport'),
        notification$: defer(() => unreadCounter.onMessagesSupportChange$),
      },
      {
        name: 'messengerSystem',
        label: messages.legacy.pages.messages.messengerSystem.navigationMenu.label,
        link: () => link.messengerSystem({ page: 'inbox' }),
        visibility$: authorization.getAuthorization$('messengerSystem'),
        notification$: defer(() => unreadCounter.onMessagesSystemChange$),
      },
    ],
  },
  {
    name: 'promotionTools',
    label: messages.legacy.pages.promotionTools.navigationMenu.label,
    icon: { active: IconList.tagSolid, inactive: IconList.tagOutline },
    weight: 15,
    visibility$: combineLatest([
      authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'mobile' })!,
      authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'desktop' })!,
      authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'mobile' })!,
      authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'desktop' })!,
      authorization.getAuthorization$('promotionToolsVideoCall')!,
      authorization.getAuthorization$('promotionToolsVideoAds', { type: 'landscape' })!,
    ]).pipe(map((access) => access.some(Boolean))),
    notification$: defer(() => authorization.getAuthorization$('promotionToolsExplicitVideos')!).pipe(
      map((access) => (access && happen(new Date()).lessThanOrEqual(date('2024-05-11').toLuxMidnight()) ? -1 : 0))
    ),
    children: [
      {
        name: 'promoTeasersMobile',
        label: messages.legacy.pages.promotionTools.promoTeasersMobile.navigationMenu.label,
        link: () => link.promotionToolsPromoTeaser({ type: 'mobile' }),
        visibility$: authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'mobile' }),
        weight: 1,
      },
      {
        name: 'promoTeasersDesktop',
        label: messages.legacy.pages.promotionTools.promoTeasersDesktop.navigationMenu.label,
        link: () => link.promotionToolsPromoTeaser({ type: 'desktop' }),
        visibility$: authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'desktop' }),
        weight: 2,
      },
      {
        name: 'eroticTeasersMobile',
        label: messages.legacy.pages.promotionTools.eroticTeasersMobile.navigationMenu.label,
        link: () => link.promotionToolsEroticTeaser({ type: 'mobile' }),
        visibility$: authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'mobile' }),
        weight: 3,
      },
      {
        name: 'eroticTeasersDesktop',
        label: messages.legacy.pages.promotionTools.eroticTeasersDesktop.navigationMenu.label,
        link: () => link.promotionToolsEroticTeaser({ type: 'desktop' }),
        visibility$: authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'desktop' }),
        weight: 4,
      },
      {
        name: 'videoCall',
        label: messages.legacy.pages.promotionTools.videoCall.navigationMenu.label,
        link: () => link.promotionToolsVideoCall(),
        visibility$: authorization.getAuthorization$('promotionToolsVideoCall'),
        weight: 6,
      },
      {
        name: 'videoAdsPortrait',
        label: messages.legacy.pages.promotionTools.videoAds.navigationMenu.label,
        link: () => link.promotionToolsVideoAds({ type: 'landscape' }),
        visibility$: authorization.getAuthorization$('promotionToolsVideoAds', { type: 'landscape' }),
        weight: 7,
      },
    ],
  },
  {
    name: 'referrals',
    label: messages.legacy.pages.referrals.navigationMenu.label,
    icon: { active: IconList.referralSolid, inactive: IconList.referralOutline },
    link: application.Oranum ? () => link.referralsBroadcaster() : undefined,
    weight: 17,
    visibility$: undefined,
    children: [
      {
        name: 'memberReferral',
        label: messages.legacy.pages.referrals.memberReferral.navigationMenu.label,
        link: () => link.referrals({ page: 'member-referral' }),
        weight: 3,
        visibility$: authorization
          .getAuthorization$('referrals', { page: 'member-referral' })!
          .pipe(map((allowed) => allowed && application.LiveJasmin)),
      },
    ],
  },
  {
    name: 'awards',
    label: messages.legacy.pages.awards.navigationMenu.label,
    icon: { active: IconList.tropheySolid, inactive: IconList.tropheyOutline },
    link: () => link.awards(),
    weight: 21,
    notification$: defer(() =>
      of(application.LiveJasmin && happen(new Date()).lessThanOrEqual(new Date('2024-01-26 10:00:00Z'))).pipe(
        map((enabled) => (enabled ? -1 : 0))
      )
    ),
    visibility$: combineLatest([
      authorization.getAuthorization$('bestOfTopModels')!,
      authorization.getAuthorization$('awards')!,
    ]).pipe(map((access) => access.some(Boolean) && performer.hasFreeShowCategory())),
  },
  {
    name: 'topExperts',
    label: messages.legacy.pages.topExperts.navigationMenu.label,
    icon: { active: IconList.tropheySolid, inactive: IconList.tropheyOutline },
    weight: 21,
    visibility$: combineLatest([
      authorization.getAuthorization$('bestOfTopModels')!,
      authorization.getAuthorization$('awards')!,
    ]).pipe(map((access) => access.some(Boolean) && application.Oranum)),
    children: [
      {
        name: 'leaderboard',
        label: messages.legacy.pages.topExperts.leaderboard.navigationMenu.label,
        link: () => link.bestOfTopModels({ page: 'topexperts' }),
        visibility$: authorization.getAuthorization$('bestOfTopModels'),
      },
      {
        name: 'awards',
        label: messages.legacy.pages.awards.navigationMenu.label,
        link: () => link.awards(),
        visibility$: authorization.getAuthorization$('awards'),
      },
    ],
  },
  {
    name: 'bestOf',
    label: messages.legacy.pages.bestOf.navigationMenu.label,
    icon: { active: IconList.tropheySolid, inactive: IconList.tropheyOutline },
    weight: 21,
    notification$: defer(() =>
      of(application.LiveJasmin && happen(new Date()).lessThanOrEqual(new Date('2024-01-26 10:00:00Z'))).pipe(
        map((enabled) => (enabled ? -1 : 0))
      )
    ),
    visibility$: combineLatest([
      authorization.getAuthorization$('bestOfTopModels')!,
      authorization.getAuthorization$('awards')!,
    ]).pipe(map((access) => access.some(Boolean) && application.LiveJasmin && !performer.hasFreeShowCategory())),
    children: [
      {
        name: 'topModels',
        label: messages.legacy.pages.bestOf.topModels.navigationMenu.label2,
        link: () => link.bestOfTopModels({ page: 'topmodels' }),
        notification$: defer(() =>
          of(
            happen(new Date()).between(new Date('2023-12-01T00:00:00+01:00'), new Date('2024-01-14T23:59:59+01:00'))
          ).pipe(map((enabled) => (enabled ? -1 : 0)))
        ),
        visibility$: authorization.getAuthorization$('bestOfTopModels'),
      },
      {
        name: 'winnersOf2022',
        label: happen(new Date()).greaterThanOrEqual(new Date('2024-01-15T11:59:59+01:00'))
          ? messages.legacy.pages.bestOf.hallOfFame.navigationMenu.label
          : messages.legacy.pages.bestOf.winnersOf2022.navigationMenu.label,
        link: () => link.bestOfTopModels({ page: 'topmodels-winners' }),
        notification$: defer(() =>
          of(
            happen(new Date()).between(new Date('2024-01-15T11:59:59+01:00'), new Date('2024-03-15T11:59:59+01:00'))
          ).pipe(map((enabled) => (enabled ? -1 : 0)))
        ),
        visibility$: authorization.getAuthorization$('bestOfTopModels'),
        new: () =>
          happen(new Date()).between(new Date('2024-01-15T11:59:59+01:00'), new Date('2024-03-15T11:59:59+01:00')),
      },
      {
        name: 'awards',
        label: messages.legacy.pages.awards.navigationMenu.label,
        link: () => link.awards(),
        notification$: defer(() =>
          of(application.LiveJasmin && happen(new Date()).lessThanOrEqual(new Date('2024-01-26 10:00:00Z'))).pipe(
            map((enabled) => (enabled ? -1 : 0))
          )
        ),
        visibility$: authorization.getAuthorization$('awards'),
      },
    ],
  },
  {
    name: 'musicLibrary',
    label: messages.legacy.pages.musicLibrary.navigationMenu.label,
    icon: { active: IconList.headphonesSolid, inactive: IconList.headphonesOutline },
    weight: 25,
    link: () => link.musicLibrary(),
    visibility$: authorization.getAuthorization$('musicLibrary'),
  },
  {
    name: 'payout',
    label: messages.legacy.pages.payout.navigationMenu.label,
    icon: { active: IconList.payoutSolid, inactive: IconList.payoutOutline },
    weight: 27,
    visibility$: undefined,
    notification$: defer(() => account.onPayoutOptionChange$.pipe(map((payoutOption) => (payoutOption ? -1 : 0)))),
    children: [
      {
        name: 'incomeStatistics',
        label: messages.legacy.pages.payout.incomeStatistics.navigationMenu.label,
        link: () => link.payout({ page: 'income-statistics' }),
        weight: 1,
        visibility$: authorization.getAuthorization$('payout', { page: 'income-statistics' }),
      },
      {
        name: 'payoutOverview',
        label: messages.legacy.pages.payout.payoutOverview.navigationMenu.label,
        link: () => link.payout({ page: 'payout-overview' }),
        weight: 2,
        visibility$: authorization.getAuthorization$('payout', { page: 'payout-overview' }),
      },
      {
        name: 'payoutOptions',
        label: messages.legacy.pages.payout.payoutOptions.navigationMenu.label,
        link: () => link.payoutOptions({ page: 'general' }),
        visibility$: authorization.getAuthorization$('payoutOptions'),
        weight: 3,
        warning$: defer(() => account.onPayoutOptionChange$),
      },
    ],
  },
  {
    name: 'earnMore',
    label: messages.legacy.pages.earnMore.navigationMenu.label,
    icon: { active: IconList.flagSolid, inactive: IconList.flagOutline },
    weight: 33,
    visibility$: undefined,
    children: [
      {
        name: 'rewardsClub',
        label: messages.legacy.pages.earnMore.rewardsClub.navigationMenu.label,
        link: () => link.earnMore({ page: 'rewards-club' }),
        weight: 3,
        visibility$: authorization.getAuthorization$('earnMore', { page: 'rewards-club' }),
      },
      {
        name: 'groupSession',
        label: messages.legacy.pages.earnMore.groupSession.navigationMenu.label,
        weight: 5,
        link: () => link.earnMore({ page: 'group-session' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'group-session' }),
      },
      {
        name: 'privateSessionDiscounts',
        label: messages.legacy.pages.earnMore.privateSessionDiscounts.navigationMenu.label,
        weight: 7,
        link: () => link.earnMore({ page: 'private-session-discounts' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'private-session-discounts' }),
      },
      {
        name: 'topMember',
        label: messages.legacy.pages.earnMore.topMember.navigationMenu.label,
        weight: 9,
        link: () => link.earnMore({ page: 'top-member' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'top-member' }),
      },
      {
        name: 'specialRequests',
        label: messages.legacy.pages.earnMore.specialRequests.navigationMenu.label,
        weight: 11,
        link: () => link.earnMore({ page: 'special-requests' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'special-requests' }),
      },
      {
        name: 'privateShows',
        label: messages.legacy.pages.earnMore.privateShows.navigationMenu.label,
        weight: 13,
        link: () => link.earnMore({ page: 'private-shows' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'private-shows' }),
      },
      {
        name: 'soldierAid',
        label: messages.legacy.pages.earnMore.soldierAid.navigationMenu.label,
        weight: 15,
        link: () => link.earnMore({ page: 'soldier-aid' }),
        visibility$: of(false),
      },
      {
        name: 'fanClub',
        label: messages.legacy.pages.earnMore.fanClub.navigationMenu.label,
        weight: 19,
        link: () => link.earnMore({ page: 'fan-club' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'fan-club' }),
      },
      {
        name: 'tipsAndSurprises',
        label: messages.legacy.pages.earnMore.tipsAndSurprises.navigationMenu.label,
        weight: 21,
        link: () => link.earnMore({ page: 'tips' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'tips' }),
      },
      {
        name: 'actionRequests',
        label: messages.legacy.pages.earnMore.actionRequests.navigationMenu.label,
        weight: 23,
        link: () => link.earnMore({ page: 'action-requests' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'action-requests' }),
      },
      {
        name: 'interactiveToy',
        label: messages.legacy.pages.earnMore.interactiveToy.navigationMenu.label,
        weight: 25,
        link: () => link.earnMore({ page: 'interactive-toy' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'interactive-toy' }),
      },
      {
        name: 'beMyFan',
        label: messages.legacy.pages.earnMore.beMyFan.navigationMenu.label,
        weight: 27,
        link: () => link.earnMore({ page: 'bemyfan' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'bemyfan' }),
      },
      {
        name: 'mobileLive',
        label: messages.legacy.pages.earnMore.mobileLive.navigationMenu.label,
        weight: 29,
        link: () => link.earnMore({ page: 'mobile-live' }),
        visibility$: authorization.getAuthorization$('earnMore'),
      },
      {
        name: 'exclusiveModel',
        label: messages.legacy.pages.earnMore.exclusiveModel.navigationMenu.label,
        weight: 31,
        link: () => link.earnMore({ page: 'exclusive-model' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'exclusive-model' }),
      },
      {
        name: 'privateShowDiscount',
        label: messages.legacy.pages.earnMore.privateShowDiscount.navigationMenu.label,
        weight: 33,
        link: () => link.earnMore({ page: 'private-show-discount' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'private-show-discount' }),
      },
      {
        name: 'messagingStreaks',
        label: messages.legacy.pages.earnMore.messagingStreaks.navigationMenu.label,
        weight: 35,
        link: () => link.earnMore({ page: 'messaging-streak' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'messaging-streak' }),
      },
      {
        name: 'promoTeasers',
        label: messages.legacy.pages.earnMore.promoTeasers.navigationMenu.label,
        weight: 37,
        link: () => link.earnMore({ page: 'promo-teasers' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'promo-teasers' }),
      },
      {
        name: 'eroticTeasers',
        label: messages.legacy.pages.earnMore.eroticTeasers.navigationMenu.label,
        weight: 39,
        link: () => link.earnMore({ page: 'erotic-teasers' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'erotic-teasers' }),
      },
      {
        name: 'kingOfTheRoom',
        label: messages.legacy.pages.earnMore.kingOfTheRoom.navigationMenu.label,
        weight: 41,
        link: () => link.earnMore({ page: 'king-of-the-room' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'king-of-the-room' }),
      },
      {
        name: 'VIPShow',
        label: messages.legacy.pages.earnMore.VIPShow.navigationMenu.label,
        weight: 43,
        link: () => link.earnMore({ page: 'vip-show' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'vip-show' }),
      },
      {
        name: 'videoAds',
        label: messages.legacy.pages.earnMore.videoAds.navigationMenu.label,
        weight: 45,
        link: () => link.earnMore({ page: 'video-ads' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'video-ads' }),
      },
      {
        name: 'messenger',
        label: messages.legacy.pages.earnMore.messenger.navigationMenu.label,
        weight: 47,
        link: () => link.earnMore({ page: 'messenger-introduction' }),
        visibility$: authorization.getAuthorization$('earnMore'),
      },
      {
        name: 'stories',
        label: messages.legacy.pages.earnMore.stories.navigationMenu.label,
        weight: 49,
        link: () => link.earnMore({ page: 'my-story' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'my-story' }),
      },
      {
        name: 'videoCalls',
        label: messages.legacy.pages.earnMore.videoCalls.navigationMenu.label,
        weight: 51,
        link: () => link.earnMore({ page: 'video-call' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'video-call' }),
      },
      {
        name: 'clubElite',
        label: messages.legacy.pages.earnMore.clubElite.navigationMenu.label,
        weight: 53,
        link: () => link.earnMore({ page: 'club-elite' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'club-elite' }),
        new: () => happen(new Date()).lessThanOrEqual(date('2023-09-12').toLuxMidnight()),
      },
      {
        name: 'premiumPromotion',
        label: messages.legacy.pages.earnMore.premiumPromotion.navigationMenu.label,
        weight: 55,
        link: () => link.earnMore({ page: 'promo-video' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'promo-video' }),
      },
      {
        name: 'privateGame',
        label: messages.legacy.pages.earnMore.privateGame.navigationMenu.label,
        weight: 57,
        link: () => link.earnMore({ page: 'private-game' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'private-game' }),
      },
      {
        name: 'liveJasminSelection',
        label: messages.legacy.pages.earnMore.liveJasminSelection.navigationMenu.label,
        weight: 59,
        link: () => link.earnMore({ page: 'jasmin-selection' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'jasmin-selection' }),
      },
      {
        name: 'protection',
        label: messages.legacy.pages.earnMore.protection.navigationMenu.label,
        weight: 61,
        link: () => link.earnMore({ page: 'protection' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'protection' }),
      },
      {
        name: 'twoWayAudio',
        label: messages.legacy.pages.earnMore.twoWayAudio.navigationMenu.label,
        weight: 63,
        link: () => link.earnMore({ page: 'two-way-audio' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'two-way-audio' }),
      },
      {
        name: 'cam2Cam',
        label: messages.legacy.pages.earnMore.cam2Cam.navigationMenu.label,
        weight: 65,
        link: () => link.earnMore({ page: 'cam-to-cam' }),
        visibility$: authorization.getAuthorization$('earnMore', { page: 'cam-to-cam' }),
      },
    ],
  },
  {
    name: 'helpAndInfo',
    label: messages.legacy.pages.helpAndInfo.navigationMenu.label,
    icon: { active: IconList.megaphoneSolid, inactive: IconList.megaphoneOutline },
    weight: 35,
    notification$: defer(() =>
      combineLatest([unreadCounter.onNewsChange$]).pipe(map((access) => (access.some(Boolean) ? -1 : 0)))
    ),
    visibility$: undefined,
    children: [
      {
        name: 'onlineSupportChat',
        label: messages.legacy.pages.helpAndInfo.onlineSupportChat.navigationMenu.label,
        link: () => link.onlineSupportChat(),
        visibility$: authorization.getAuthorization$('onlineSupportChat'),
        weight: 2,
      },
      {
        name: 'incomeShares',
        label: messages.legacy.pages.helpAndInfo.incomeShares.navigationMenu.label,
        link: () => link.help({ type: 'income-shares' }),
        visibility$: authorization.getAuthorization$('help', { type: 'income-shares' }),
        weight: 3,
      },
      {
        name: 'wiki',
        label: messages.legacy.pages.helpAndInfo.wiki.navigationMenu.label,
        link: () =>
          application.Oranum
            ? parse.url(settings.envVars.wikiUri)
            : parse.url(settings.envVars.wikiUri, 'Model+Wiki+Home'),
        visibility$: combineLatest([application.onViewportTypeChange$, authorization.getAuthorization$('help')!]).pipe(
          map(([viewport, status]) => status && viewport === 'desktop')
        ),
        weight: 4,
      },
    ],
  },
];

export default menu;
