import { combineLatest, defer, iif, map, of } from 'rxjs';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';
import performer from 'store/performer';
import application from 'services/application';
import performerProfilePictures from 'store/performer-profile-pictures';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: user.onChange$.pipe(
    evalAuthorization(
      ({ viewTypeId }) => is.nullish(viewTypeId),
      () =>
        iif(
          () => user.isModelView() && application.LiveJasmin,
          defer(() =>
            combineLatest([
              performer.onCategoryChange$,
              performerProfilePictures.onSelectedPictureApproveStatusChange$,
            ]).pipe(
              map(
                () =>
                  performer.hasActiveStatus() &&
                  (performer.hasNudeCategory() || performer.hasAmateurCategory() || performer.hasHotFlirtCategory()) &&
                  performerProfilePictures.hasAcceptedGlamourProfilePicture()
              )
            )
          ),
          of(false)
        )
    )
  ),
};

export default authorization;
