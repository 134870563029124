import React from 'react';
import { IntlProvider } from 'react-intl';
import type { OnErrorFn } from '@formatjs/intl';
import { getBrowserPreferredLocale } from 'services/i18n/browser-languages';
import translations from 'services/i18n/translations';
import Router from 'services/routing/router';
import appLanguage from 'services/i18n/language';
import { captureException } from 'services/sentry';
import useReadonlyObservable from 'enhancers/use-readonly-observable';
import Loading from 'pages/shared/loading';
import Layout from 'pages/layout';

import RootSeo from './root-seo';
import DataTracking from './data-tracking';

const Root: React.FunctionComponent<unknown> = () => {
  const [translationList, setTranslations] = React.useState<Record<string, string>>({});
  const [currentLanguage] = useReadonlyObservable(appLanguage.onChange$, appLanguage.current);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production' && navigator.serviceWorker) {
      void import('workbox-window').then(({ Workbox }) => {
        const wb = new Workbox('/sw.js', { scope: '/' });

        wb.addEventListener('waiting', () => {
          wb.addEventListener('controlling', () => {
            window.location.reload();
          });

          wb.messageSkipWaiting();
        });

        void wb.register();
      });
    }
  }, []);

  React.useEffect(() => {
    translations[currentLanguage]()
      .then(setTranslations)
      .catch((error) => {
        captureException(error);
      });
  }, [currentLanguage]);

  const handleOnIntlError: OnErrorFn = (error): void => {
    if (process.env.NODE_ENV === 'development') {
      if (error.code === 'MISSING_TRANSLATION') {
        return;
      }

      throw error;
    }
  };

  return (
    <React.Fragment>
      <RootSeo />
      <DataTracking />
      <IntlProvider
        locale={getBrowserPreferredLocale(currentLanguage)}
        messages={translationList}
        onError={handleOnIntlError}
      >
        <Layout>
          <React.Suspense fallback={<Loading />}>
            <Router />
          </React.Suspense>
        </Layout>
      </IntlProvider>
    </React.Fragment>
  );
};

export default React.memo(Root);
