import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.performance.seo.livejasmin.title',
      defaultMessage: 'Performance',
    },
    description: {
      id: 'pages.performance.seo.livejasmin.description',
      defaultMessage: 'Performance',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.performance.seo.oranum.title',
      defaultMessage: 'Performance',
    },
    description: {
      id: 'pages.pages.performance.seo.oranum.description',
      defaultMessage: 'Performance',
    },
  }),
};

const info = {
  ...defineMessages({
    title: {
      id: 'pages.performance.info.title',
      defaultMessage: 'Your journey to success!',
    },
    motivation: {
      id: 'pages.performance.info.motivation',
      defaultMessage:
        'Unleash your potential and become more successful than ever before. Follow these tips, complete each goal, and build a thriving career!',
    },
    completionRatio: {
      id: 'pages.performance.info.completionRatio',
      defaultMessage: '<b>{completed}</b>/{total} completed',
    },
  }),
};

const motivationalMessages = {
  ...defineMessages({
    almostThere: {
      id: 'pages.performance.motivationalMessages.almostThere',
      defaultMessage: 'Almost there!',
    },
    greatJob: {
      id: 'pages.performance.motivationalMessages.greatJob',
      defaultMessage: 'Great job!',
    },
    keepItUp: {
      id: 'pages.performance.motivationalMessages.keepItUp',
      defaultMessage: 'Keep it up!',
    },
  }),
};

const tabs = {
  ...defineMessages({
    trafficScore: {
      id: 'pages.performance.tabs.trafficScore',
      defaultMessage: 'Traffic Score',
    },
    conversionScore: {
      id: 'pages.performance.tabs.conversionScore',
      defaultMessage: 'Conversion Score',
    },
    engagementScore: {
      id: 'pages.performance.tabs.engagementScore',
      defaultMessage: 'Engagement Score',
    },
  }),
};

const tabsShort = {
  ...defineMessages({
    trafficScore: {
      id: 'pages.performance.tabsShort.trafficScore',
      defaultMessage: 'Traffic',
    },
    conversionScore: {
      id: 'pages.performance.tabsShort.conversionScore',
      defaultMessage: 'Conversion',
    },
    engagementScore: {
      id: 'pages.performance.tabsShort.engagementScore',
      defaultMessage: 'Engagement',
    },
  }),
};

export default {
  pages: {
    performance: {
      seo,
      info,
      tabs,
      tabsShort,
      motivationalMessages,
      navigationMenu: {
        ...defineMessages({
          label: {
            id: 'pages.performance.navigationMenu.label',
            defaultMessage: 'Performance',
          },
        }),
      },
    },
  },
};
