import { defineMessages } from 'react-intl';

export default {
  pages: {
    shared: {
      loading: defineMessages({
        message: {
          id: 'pages.shared.loading.message',
          defaultMessage: 'Loading...',
        },
      }),
    },
  },
};
