import React from 'react';
import Helmet from 'react-helmet';
import settings from 'configurations/application';
import application from 'services/application';

import { getPortalURL } from './utils';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/Performance/dns-prefetch
 * @constructor
 */
const RootSeo: React.FunctionComponent<unknown> = () => {
  const mscPortalURL = getPortalURL();
  const { apiGwBaseUri } = settings.envVars;

  return (
    <Helmet>
      <link href={`/favicon_${application.current}.ico`} rel="shortcut icon" type="image/x-icon" />

      <link href={apiGwBaseUri} rel="dns-prefetch" />
      <link href={apiGwBaseUri} rel="preconnect" />

      {mscPortalURL && <link href={mscPortalURL} rel="dns-prefetch" />}
      {mscPortalURL && <link href={mscPortalURL} rel="preconnect" />}

      <link rel="manifest" href={`/assets/manifest/${application.current}/manifest.webmanifest`} />
    </Helmet>
  );
};

export default React.memo(RootSeo);
