import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const HitPortalSelect = React.lazy(() => import(/* webpackPreload: true */ '../common'));
const DirectHit = React.lazy(() => import(/* webpackPreload: true */ '../direct-hit'));

const routes: Array<Route> = [
  {
    name: id.dashboard,
    path: '/:lang?/:alias(dashboard)?',
    element: <HitPortalSelect />,
  },
  {
    name: id.contact,
    path: '/:lang?/online-support-chat/topic/:topic(other|technical|registration|picture-or-video)',
    element: <DirectHit />,
  },
  {
    name: id.help,
    path: '/:lang?/help/:type(income-shares)',
    element: <DirectHit />,
  },
  {
    name: id.cookies,
    path: '/:lang?/cookie-policy',
    element: <DirectHit />,
  },
  {
    name: id.faq,
    path: '/:lang?/faq',
    element: <DirectHit />,
  },
  {
    name: id.recoverPassword,
    path: '/:lang?/forgot-password',
    element: <DirectHit />,
  },
  {
    name: id.privacy,
    path: '/:lang?/privacy-policy',
    hashParams: ['marketing', 'sensitiveData', 'biometricData'],
    element: <DirectHit />,
  },
  {
    name: id.terms,
    path: '/:lang?/services-agreement',
    element: <DirectHit />,
  },
  {
    name: id.certifiedPartners,
    path: '/:lang?/certified-partners',
    element: <DirectHit />,
  },
  {
    name: id.statisticsFanClub,
    path: '/:lang?/statistics/fan-club',
    element: <HitPortalSelect />,
  },
  {
    name: id.profile,
    // eslint-disable-next-line max-len
    path: '/:lang?/profile/:page(introduction|profile-picture|prices|action-requests)/:type(non-explicit|willingness)?',
    element: <HitPortalSelect />,
  },
  {
    name: id.myContent,
    path: '/:lang?/my-content/:page(paying-snapshot)',
    element: <HitPortalSelect />,
  },
  {
    name: id.myContentShorts,
    path: '/:lang?/my-content/shorts/:privacy(free|premium)?/:shortsId?',
    element: <HitPortalSelect />,
  },
  {
    name: id.myContentEditor,
    path: '/:lang?/my-content/editor/:type(free|premium)',
    element: <HitPortalSelect />,
    searchParams: [':contentId', ':mediaType', ':paymentType'],
    hashParams: ['photos_folder', 'notes', 'videos_folder'],
  },
  {
    name: id.messenger,
    path: '/:lang?/messenger/:page(current-chats|recommended)?/:threadId?',
    searchParams: [':memberNick', ':filter'],
    element: <HitPortalSelect />,
  },
  {
    name: id.messengerAutomatedMessages,
    path: '/:lang?/messenger/automated-messages',
    element: <HitPortalSelect />,
  },
  {
    name: id.messengerSupport,
    path: '/:lang?/support-messenger/support/:page(inbox|sent|trash)',
    element: <HitPortalSelect />,
  },
  {
    name: id.messengerSystem,
    path: '/:lang?/support-messenger/system/:page(inbox|sent|trash)',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsEroticTeaser,
    path: '/:lang?/promotion-tools/erotic-teaser/:type(mobile|desktop)',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsPromoTeaser,
    path: '/:lang?/promotion-tools/promo-teaser/:type(mobile|desktop)',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsVideoAds,
    path: '/:lang?/promotion-tools/video-ads/:type(portrait|landscape)',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsVideoCall,
    path: '/:lang?/promotion-tools/video-call',
    element: <HitPortalSelect />,
  },
  {
    name: id.bestOfTopModels,
    path: '/:lang?/bestof/:page(topmodels|topexperts|topmodels-winners)',
    element: <HitPortalSelect />,
  },
  {
    name: id.awards,
    path: '/:lang?/awards',
    element: <HitPortalSelect />,
  },
  {
    name: id.referral,
    path: '/:lang?/referral',
    element: <HitPortalSelect />,
  },
  {
    name: id.referrals,
    path: '/:lang?/referrals/:page(member-referral)',
    element: <HitPortalSelect />,
  },
  {
    name: id.musicLibrary,
    path: '/:lang?/music-library',
    element: <HitPortalSelect />,
  },
  {
    name: id.payout,
    path: '/:lang?/payout/:page(income-statistics|payout-overview|billing-address)',
    element: <HitPortalSelect />,
  },
  {
    name: id.payoutOptions,
    path: '/:lang?/payout/options/:page(general|details)',
    element: <HitPortalSelect />,
  },
  {
    name: id.earnMore,
    // eslint-disable-next-line max-len
    path: '/:lang?/earn-more/:page(rewards-club|club-elite|protection|cam-to-cam|two-way-audio|jasmin-selection|private-game|fan-club|promo-video|tips-and-surprises|action-requests|interactive-toy|bemyfan|mobile-live|exclusive-model|private-show-discount|messaging-streak|erotic-teasers|promo-teasers|king-of-the-room|vip-show|messenger-introduction|video-call|my-story|soldier-aid|loyalfans|tips|special-requests|private-shows|video-ads|stories|group-session|private-session-discounts|top-member)',
    element: <HitPortalSelect />,
  },
  {
    name: id.onlineSupportChat,
    path: '/:lang?/online-support-chat',
    element: <HitPortalSelect />,
  },
  {
    name: id.statisticsStudio,
    path: '/:lang?/statistics/studio/dashboard',
    element: <HitPortalSelect />,
  },
];

export default routes;
