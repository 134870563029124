import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';
import date from 'utils/date';
import happen from 'utils/happen';

import messages from '../explicit-videos.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  parent: 'promotionTools',
  weight: 5,
  label: messages.pages.promotionTools.explicitVideos.navigationMenu.label,
  visibility$: authorization.getAuthorization$(id),
  link: () => link[id](),
  new: () => happen(new Date()).lessThanOrEqual(date('2024-05-11').toLuxMidnight()),
};

export default menu;
