import { of } from 'rxjs';
import type { RouteAuthorization } from 'contracts';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: of(true),
};

export default authorization;
