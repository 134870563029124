import { defineMessages } from 'react-intl';

export default {
  pages: {
    referrals: {
      broadcaster: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.referrals.broadcaster.navigationMenu.label',
              defaultMessage: 'Referrals',
            },
          }),
        },
      },
    },
  },
};
