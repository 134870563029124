import { map, defer, of } from 'rxjs';
import is from 'utils/is';
import happen from 'utils/happen';
import date from 'utils/date';
import user from 'store/user';
import application from 'services/application';
import performer from 'store/performer';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import type { RouteAuthorization } from 'contracts';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: defer(() => {
    return user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => {
          if (user.isStudioView()) {
            return of(false);
          }

          const isSelectedRequirementActive = happen(new Date()).lessThanOrEqual(date('2024-06-05').toLuxMidnight());

          return performer.onChange$.pipe(
            map(
              ({ flags }) =>
                application.LiveJasmin &&
                performer.hasActiveStatus() &&
                (isSelectedRequirementActive ? flags.isSelected : true)
            )
          );
        }
      )
    );
  }),
};

export default authorization;
