import type { RouteAuthorization } from 'contracts';
import { defer } from 'rxjs';
import is from 'utils/is';

import parentAuth from '../../@@global/authorization';

import id from './id';

const authorization: RouteAuthorization = {
  name: id.performanceConversionScore,
  authorization$: defer(() =>
    is.func(parentAuth.authorization$) ? parentAuth.authorization$({}) : parentAuth.authorization$
  ),
};

export default authorization;
