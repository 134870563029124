import React from 'react';
import { useIntl } from 'react-intl';
import Html from 'components/html';
import Spinner from 'components/loading';
import toArray from 'utils/to-array';
import application from 'services/application';
import type { StyledElement } from 'contracts';

import messages from './loading.i18n';
import { testId } from './loading.settings';
import styles from './loading.module.scss';

interface LoadingElement extends StyledElement<HTMLDivElement> {
  center?: boolean;
  hidden?: boolean;
  onMount?: () => void;
  onUnmount?: () => void;
}

const Loading: React.FunctionComponent<LoadingElement> = (props) => {
  const { center = true, className, style, hidden = false, onMount, onUnmount } = props;
  const { formatMessage } = useIntl();

  React.useEffect(
    function Effect() {
      application.startLoading(Effect);

      onMount?.();

      return () => {
        application.stopLoading(Effect);
        onUnmount?.();
      };
    },
    [onMount, onUnmount]
  );

  if (hidden) return null;

  return (
    <Html.div
      testId={testId.loading}
      className={[styles.loading, 'row', center && 'h-100', 'align-items-center', ...toArray(className)]}
      style={style}
    >
      <Html.div className={['d-inline', 'my-5']}>
        <Spinner delay={0} size={36} />
        <Html.p typography="body2" className="text-center text-uppercase mt-4">
          {formatMessage(messages.pages.shared.loading.message)}
        </Html.p>
      </Html.div>
    </Html.div>
  );
};

export default Loading;
