import { defineMessages } from 'react-intl';

export default {
  pages: {
    promotionTools: {
      explicitVideos: {
        seo: {
          title: {
            id: 'pages.promotionTools.explicitVideos.seo.title',
            defaultMessage: 'Explicit Videos',
          },
          description: {
            id: 'pages.promotionTools.explicitVideos.seo.description',
            defaultMessage: 'Explicit Videos',
          },
        },
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.promotionTools.explicitVideos.navigationMenu.label',
              defaultMessage: 'Explicit Videos',
            },
          }),
        },
        ...defineMessages({
          moreInfo: {
            id: 'pages.promotionTools.explicitVideos.moreInfo',
            defaultMessage: 'More info',
          },
        }),
      },
    },
  },
};
