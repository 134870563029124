import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import { IconList } from 'components/icon';
import type { NavigationMenuItem } from 'contracts';

import messages from '../../profile/personal-data/personal-data.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  label: messages.pages.profile.personalData.navigationMenu.label,
  icon: { active: IconList.settingsSolid, inactive: IconList.settingsOutline },
  link: () => link[id](),
  visibility$: authorization.getAuthorization$(id),
  weight: 13,
};

export default menu;
