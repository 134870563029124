import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../settings'));
const AsyncSkeleton = React.lazy(() => import('../settings.skeleton'));

const routes: Route[] = [
  {
    name: id,
    path: '/:lang?/settings',
    element: <AsyncPage />,
    skeleton: <AsyncSkeleton />,
  },
];

export default routes;
