import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../model-referral.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  parent: 'referrals',
  label: messages.pages.referrals.modelReferral.navigationMenu.label,
  link: () => link.referralsModelReferral(),
  weight: 1,
  visibility$: authorization.getAuthorization$(id),
};

export default menu;
