import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import { IconList } from 'components/icon';
import type { NavigationMenuItem } from 'contracts';

import messages from '../broadcaster.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  label: messages.pages.referrals.broadcaster.navigationMenu.label,
  icon: { active: IconList.referralSolid, inactive: IconList.referralOutline },
  link: () => link.referralsBroadcaster(),
  weight: 17,
  visibility$: authorization.getAuthorization$(id),
};

export default menu;
