import { defer, map, of } from 'rxjs';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';
import performer from 'store/performer';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import is from 'utils/is';

import id from './id';

const authorization: RouteAuthorization[] = [
  {
    name: id,
    authorization$: defer(() => {
      return user.onChange$.pipe(
        evalAuthorization(
          ({ viewTypeId }) => is.nullish(viewTypeId),
          () => {
            if (user.isStudioView()) {
              return of(!user.isLimitedStudioAccountType());
            }

            return performer.onChange$.pipe(map((perf) => !user.isImpersonating(perf?.personIds)));
          }
        )
      );
    }),
  },
];

export default authorization;
