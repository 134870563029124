import { map, defer } from 'rxjs';
import application from 'services/application';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';

import parentAuth from '../../@@global/authorization';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: defer(() =>
    (is.func(parentAuth.authorization$) ? parentAuth.authorization$({}) : parentAuth.authorization$).pipe(
      map((parent) => parent && application.Oranum)
    )
  ),
};

export default authorization;
