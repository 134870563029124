import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.profile.privacy.seo.livejasmin.title',
      defaultMessage: 'Privacy',
    },
    description: {
      id: 'pages.profile.privacy.seo.livejasmin.description',
      defaultMessage: 'Privacy',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.profile.privacy.seo.oranum.title',
      defaultMessage: 'Privacy',
    },
    description: {
      id: 'pages.profile.privacy.seo.oranum.description',
      defaultMessage: 'Privacy',
    },
  }),
};

export default {
  pages: {
    profile: {
      privacy: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.profile.privacy.navigationMenu.label',
              defaultMessage: 'Privacy',
            },
          }),
        },
        tabs: {
          ...defineMessages({
            countryBan: {
              id: 'pages.profile.privacy.tabs.countryBan',
              defaultMessage: 'Country Ban',
            },
            bannedWords: {
              id: 'pages.profile.privacy.tabs.bannedWords',
              defaultMessage: 'Banned Words',
            },
          }),
        },
      },
    },
  },
};
