// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { onHistoryChange$ } from 'services/routing/events';
import location from 'services/routing/location';
import navigation from 'services/navigation';
import history from 'services/routing/history';
import application from 'services/application/application';
import appLanguage from 'services/i18n/language';
import { sendPageView } from 'services/google-analytics';
import { persist } from 'services/google-analytics/init';
import { persist as hotjarPersist, onHistoryChange } from 'services/hotjar';
import user from 'store/user';
import account from 'store/account';
import is from 'utils/is';

const DataTracking: React.FunctionComponent = () => {
  React.useEffect(() => {
    const onHistoryChangeSubscription = onHistoryChange$.subscribe((route) => {
      const currentLanguage = appLanguage.current;

      if (!location.params().lang) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return navigation[route.name]({ ...location.params(), lang: currentLanguage });
      }

      // Normalize urls by removing trailing slash from path if found
      if (window.location.pathname.endsWith('/')) {
        const url = new URL(window.location.href);

        url.pathname = window.location.pathname.slice(0, -1);

        history.replace(url.toString());
      }

      application.scrollTop(0);

      sendPageView(route.name, currentLanguage);
      onHistoryChange(currentLanguage);

      if (process.env.NODE_ENV === 'production') {
        void navigator?.serviceWorker?.ready?.then((registration) => {
          void registration?.active?.postMessage({ type: 'NAUTILUS_NAVIGATION', data: window.location.href });
        });
      }
    });

    const onUserChangeSubscription = combineLatest([user.onChange$, account.onChange$, appLanguage.onChange$])
      .pipe(
        filter(([{ viewTypeId }]) => !is.nullish(viewTypeId)),
        map(
          ([
            { viewType, viewTypeId, userAccountType, limitedAccess, hasMultiplePerformers },
            accountData,
            language,
          ]) => ({
            viewTypeId,
            viewType,
            userAccountType,
            limitedAccess,
            hasMultiplePerformers,
            testAccount: accountData?.flags?.isTestAccount ?? false,
            language,
          })
        ),
        distinctUntilChanged((prev, next) => {
          return Object.keys(prev).every((key) => prev[key] === next[key]);
        })
      )
      .subscribe((data) => {
        const params = {
          viewType: data.viewType,
          userId: data.viewTypeId!.toString(),
          userAccountType: data.userAccountType,
          limitedAccess: data.limitedAccess,
          multiplePerformers: data.hasMultiplePerformers,
          testAccount: data.testAccount,
          language: data.language,
        };

        hotjarPersist(params);
        persist(params);
      });

    return () => {
      onHistoryChangeSubscription.unsubscribe();
      onUserChangeSubscription.unsubscribe();
    };
  }, []);

  return null;
};

export default DataTracking;
