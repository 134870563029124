import { defer, map, of } from 'rxjs';
import user from 'store/user';
import moduleAuth from 'store/module-authorization';
import is from 'utils/is';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import filterWhileNullish from 'utils/rxjs/filter-while-nullish';
import type { RouteAuthorization } from 'contracts';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: defer(() =>
    user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => {
          if (user.isStudioView()) return of(false);

          return moduleAuth.onChange$.pipe(
            filterWhileNullish(),
            map((data) => data.scheduledShows),

            map((status) => is.array(status))
          );
        }
      )
    )
  ),
};

export default authorization;
