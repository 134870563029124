import settings from 'configurations/application';
import env from 'utils/environment';

/**
 * Get msc-portal URL for backoffice prefetch links.
 * @returns {string} - msc-portal URL
 * */

function getPortalURL(): string {
  if (env.isLocalhost) {
    return '';
  }

  const { host, protocol } = window.location;
  const { dev, production } = settings.mscPortal.redirection;

  if (env.isDevelopment) {
    return `${protocol}//${host.replace(dev.match, dev.replace)}`;
  }

  return `${protocol}//${host.replace(production.match, production.replace)}`;
}

export { getPortalURL };
