import { defineMessages } from 'react-intl';

export default {
  legacy: {
    pages: {
      dashboard: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.dashboard.navigationMenu.label',
              defaultMessage: 'Home',
            },
          }),
        },
      },
      statistics: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.statistics.navigationMenu.label',
              defaultMessage: 'Statistics',
            },
          }),
        },
        incomeOverview: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.statistics.incomeOverview.navigationMenu.label',
                defaultMessage: 'Income Overview',
              },
            }),
          },
        },
        payingMembers: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.statistics.payingMembers.navigationMenu.label',
                defaultMessage: 'Paying Members',
              },
            }),
          },
        },
        fanClub: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.statistics.fanClub.navigationMenu.label',
                defaultMessage: 'Fan Club Members',
              },
            }),
          },
        },
      },
      profile: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.profile.navigationMenu.label',
              defaultMessage: 'Profile',
            },
          }),
        },
        profilePhoto: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.profile.profilePhoto.navigationMenu.label',
                defaultMessage: 'Profile Photo',
              },
            }),
          },
        },
        accountData: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.profile.accountData.navigationMenu.label',
                defaultMessage: 'Account Data',
              },
            }),
          },
        },
        personalData: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.profile.personalData.navigationMenu.label',
                defaultMessage: 'Personal Data',
              },
            }),
          },
        },
        prices: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.profile.prices.navigationMenu.label',
                defaultMessage: 'Prices',
              },
            }),
          },
        },
        privacy: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.profile.privacy.navigationMenu.label',
                defaultMessage: 'Privacy',
              },
            }),
          },
        },
        countryBan: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.profile.countryBan.navigationMenu.label',
                defaultMessage: 'Country Ban',
              },
            }),
          },
        },
      },
      myContent: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.myContent.navigationMenu.label',
              defaultMessage: 'My Content',
            },
          }),
        },
        freeContent: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.myContent.freeContent.navigationMenu.label',
                defaultMessage: 'Free Content',
              },
            }),
          },
        },
        premiumContent: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.myContent.premiumContent.navigationMenu.label',
                defaultMessage: 'Premium Content',
              },
            }),
          },
        },
        payingSnapshots: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.myContent.payingSnapshots.navigationMenu.label',
                defaultMessage: 'Paying Snapshots',
              },
            }),
          },
        },
      },
      messages: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.messages.navigationMenu.label',
              defaultMessage: 'Messages',
            },
          }),
        },
        member: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.messages.member.navigationMenu.label',
                defaultMessage: 'Member',
              },
            }),
          },
        },
        automatedMessages: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.messages.automatedMessages.navigationMenu.label',
                defaultMessage: 'Automated Messages',
              },
            }),
          },
        },
        messengerSupport: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.messages.support.navigationMenu.label',
                defaultMessage: 'Support',
              },
            }),
          },
        },
        messengerSystem: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.messages.system.navigationMenu.label',
                defaultMessage: 'System',
              },
            }),
          },
        },
      },
      promotionTools: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.promotionTools.navigationMenu.label',
              defaultMessage: 'Promotion Tools',
            },
          }),
        },
        promoTeasersMobile: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.promotionTools.promoTeasersMobile.navigationMenu.label',
                defaultMessage: 'Mobile Teasers',
              },
            }),
          },
        },
        promoTeasersDesktop: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.promotionTools.promoTeasersDesktop.navigationMenu.label',
                defaultMessage: 'Desktop Teasers',
              },
            }),
          },
        },
        eroticTeasersMobile: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.promotionTools.eroticTeasersMobile.navigationMenu.label',
                defaultMessage: 'Mobile Teasers',
              },
            }),
          },
        },
        eroticTeasersDesktop: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.promotionTools.eroticTeasersDesktop.navigationMenu.label',
                defaultMessage: 'Desktop Teasers',
              },
            }),
          },
        },
        videoCall: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.promotionTools.videoCall.navigationMenu.label',
                defaultMessage: 'Video Call Teasers',
              },
            }),
          },
        },
        videoAds: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.promotionTools.videoAds.navigationMenu.label',
                defaultMessage: 'Video Ads',
              },
            }),
          },
        },
      },
      topExperts: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.topExperts.navigationMenu.label',
              defaultMessage: 'Top Experts',
            },
          }),
        },
        leaderboard: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.topExperts.leaderboard.navigationMenu.label',
                defaultMessage: 'Leaderboard',
              },
            }),
          },
        },
        awards: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.topExperts.awards.navigationMenu.label',
                defaultMessage: 'Awards',
              },
            }),
          },
        },
      },
      bestOf: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.bestOf.navigationMenu.label',
              defaultMessage: 'Best Models',
            },
          }),
        },
        topModels: {
          navigationMenu: {
            ...defineMessages({
              label2: {
                id: 'legacy.pages.bestOf.topModels.navigationMenu.label2',
                defaultMessage: 'Top Models',
              },
            }),
          },
        },
        winnersOf2022: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.bestOf.winnersOf2022.navigationMenu.label',
                defaultMessage: 'Winners Of 2022',
              },
            }),
          },
        },
        hallOfFame: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.bestOf.hallOfFame.navigationMenu.label',
                defaultMessage: 'Hall of Fame',
              },
            }),
          },
        },
      },
      awards: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.awards.navigationMenu.label',
              defaultMessage: 'Awards',
            },
          }),
        },
      },
      referrals: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.referrals.navigationMenu.label',
              defaultMessage: 'Referrals',
            },
          }),
        },
        modelReferral: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.referrals.modelReferral.navigationMenu.label',
                defaultMessage: 'Model Referral',
              },
            }),
          },
        },
        memberReferral: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.referrals.memberReferral.navigationMenu.label',
                defaultMessage: 'Member Referral',
              },
            }),
          },
        },
      },
      musicLibrary: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.musicLibrary.navigationMenu.label',
              defaultMessage: 'Music Library',
            },
          }),
        },
      },
      payout: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.payout.navigationMenu.label',
              defaultMessage: 'Payout',
            },
          }),
        },
        incomeStatistics: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.payout.incomeStatistics.navigationMenu.label',
                defaultMessage: 'Income Statistics',
              },
            }),
          },
        },
        payoutOverview: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.payout.payoutOverview.navigationMenu.label',
                defaultMessage: 'Payout Overview',
              },
            }),
          },
        },
        directPayout: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.payout.directPayout.navigationMenu.label',
                defaultMessage: 'Direct Payout',
              },
            }),
          },
        },
        payoutOptions: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.payout.payoutOptions.navigationMenu.label',
                defaultMessage: 'Payout Options',
              },
            }),
          },
        },
        payoutFAQ: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.payout.payoutFAQ.navigationMenu.label',
                defaultMessage: 'Payout FAQ',
              },
            }),
          },
        },
      },
      loyalfans: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.loyalfans.navigationMenu.label',
              defaultMessage: 'Loyalfans',
            },
          }),
        },
      },
      earnMore: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.earnMore.navigationMenu.label',
              defaultMessage: 'Earn More',
            },
          }),
        },
        rewardsClub: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.rewardsClub.navigationMenu.label',
                defaultMessage: 'Rewards Club',
              },
            }),
          },
        },
        groupSession: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.groupSession.navigationMenu.label',
                defaultMessage: 'Group Session',
              },
            }),
          },
        },
        privateSessionDiscounts: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.privateSessionDiscounts.navigationMenu.label',
                defaultMessage: 'Private Session Discounts',
              },
            }),
          },
        },
        topMember: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.topMember.navigationMenu.label',
                defaultMessage: 'Top Member',
              },
            }),
          },
        },
        specialRequests: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.specialRequests.navigationMenu.label',
                defaultMessage: 'Special Requests',
              },
            }),
          },
        },
        privateShows: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.privateShows.navigationMenu.label',
                defaultMessage: 'Private Shows',
              },
            }),
          },
        },
        soldierAid: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.soldierAid.navigationMenu.label',
                defaultMessage: 'Soldier Aid',
              },
            }),
          },
        },
        fanClub: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.fanClub.navigationMenu.label',
                defaultMessage: 'Fan Club',
              },
            }),
          },
        },
        tipsAndSurprises: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.tipsAndSurprises.navigationMenu.label',
                defaultMessage: 'Tips & Surprises',
              },
            }),
          },
        },
        actionRequests: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.actionRequests.navigationMenu.label',
                defaultMessage: 'Action Requests',
              },
            }),
          },
        },
        interactiveToy: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.interactiveToy.navigationMenu.label',
                defaultMessage: 'Interactive Toy',
              },
            }),
          },
        },
        beMyFan: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.beMyFan.navigationMenu.label',
                defaultMessage: 'BeMyFan',
              },
            }),
          },
        },
        mobileLive: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.mobileLive.navigationMenu.label',
                defaultMessage: 'Mobile Live',
              },
            }),
          },
        },
        exclusiveModel: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.exclusiveModel.navigationMenu.label',
                defaultMessage: 'Exclusive Model',
              },
            }),
          },
        },
        privateShowDiscount: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.privateShowDiscount.navigationMenu.label',
                defaultMessage: 'Private Show Discount',
              },
            }),
          },
        },
        messagingStreaks: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.messagingStreaks.navigationMenu.label',
                defaultMessage: 'Messaging Streaks',
              },
            }),
          },
        },
        promoTeasers: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.promoTeasers.navigationMenu.label',
                defaultMessage: 'Promo Teasers',
              },
            }),
          },
        },
        eroticTeasers: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.eroticTeasers.navigationMenu.label',
                defaultMessage: 'Erotic Teasers',
              },
            }),
          },
        },
        kingOfTheRoom: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.kingOfTheRoom.navigationMenu.label',
                defaultMessage: 'King Of the Room',
              },
            }),
          },
        },
        videoAds: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.videoAds.navigationMenu.label',
                defaultMessage: 'Video Ads',
              },
            }),
          },
        },
        stories: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.stories.navigationMenu.label',
                defaultMessage: 'Stories',
              },
            }),
          },
        },
        VIPShow: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.VIPShow.navigationMenu.label',
                defaultMessage: 'VIP Show',
              },
            }),
          },
        },
        messenger: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.messenger.navigationMenu.label',
                defaultMessage: 'Messenger',
              },
            }),
          },
        },
        videoCalls: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.videoCalls.navigationMenu.label',
                defaultMessage: 'Video Calls',
              },
            }),
          },
        },
        clubElite: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.clubElite.navigationMenu.label',
                defaultMessage: 'Club Elite',
              },
            }),
          },
        },
        premiumPromotion: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.premiumPromotion.navigationMenu.label',
                defaultMessage: 'Premium Promotion',
              },
            }),
          },
        },
        privateGame: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.privateGame.navigationMenu.label',
                defaultMessage: 'Private Game',
              },
            }),
          },
        },
        liveJasminSelection: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.liveJasminSelection.navigationMenu.label',
                defaultMessage: 'LiveJasmin Selection',
              },
            }),
          },
        },
        protection: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.protection.navigationMenu.label',
                defaultMessage: 'Protection',
              },
            }),
          },
        },
        twoWayAudio: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.twoWayAudio.navigationMenu.label',
                defaultMessage: 'Two-Way Audio',
              },
            }),
          },
        },
        cam2Cam: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.cam2Cam.navigationMenu.label',
                defaultMessage: 'Cam2Cam',
              },
            }),
          },
        },
        payingSnapshots: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.earnMore.payingSnapshots.navigationMenu.label',
                defaultMessage: 'Paying Snapshots',
              },
            }),
          },
        },
      },
      helpAndInfo: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.helpAndInfo.navigationMenu.label',
              defaultMessage: 'Help & Info',
            },
          }),
        },
        onlineSupportChat: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.helpAndInfo.onlineSupportChat.navigationMenu.label',
                defaultMessage: 'Online Support Chat',
              },
            }),
          },
        },
        incomeShares: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.helpAndInfo.incomeShares.navigationMenu.label',
                defaultMessage: 'Income Shares',
              },
            }),
          },
        },
        wiki: {
          navigationMenu: {
            ...defineMessages({
              label: {
                id: 'legacy.pages.helpAndInfo.wiki.navigationMenu.label',
                defaultMessage: 'Wiki',
              },
            }),
          },
        },
      },
      models: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.models.navigationMenu.label',
              defaultMessage: 'Models',
            },
          }),
        },
      },
      broadcasters: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.broadcasters.navigationMenu.label',
              defaultMessage: 'Broadcasters',
            },
          }),
        },
      },
      statisticsStudio: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.statisticsStudio.navigationMenu.label',
              defaultMessage: 'Statistics',
            },
          }),
        },
      },
      personalDataStudio: {
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'legacy.pages.personalDataStudio.navigationMenu.label',
              defaultMessage: 'Personal Data',
            },
          }),
        },
      },
    },
  },
};
