import { of, defer } from 'rxjs';
import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import { IconList } from 'components/icon';
import type { NavigationMenuItem } from 'contracts';

import messages from '../scheduled-shows.i18n';

import id from './id';

const menu: NavigationMenuItem[] = [
  {
    name: id,
    label: messages.pages.scheduledShows.navigationMenu.label,
    icon: { active: IconList.calendarSolid, inactive: IconList.calendarOutline },
    link: () => link[id](),
    weight: 12,
    notification$: defer(() => of(-1)),
    visibility$: authorization.getAuthorization$(id),
  },
];

export default menu;
