import { defer, of, map } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import performer from 'store/performer';
import is from 'utils/is';
import type { RouteAuthorization } from 'contracts';
import user from 'store/user';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: defer(() =>
    user.onChange$.pipe(
      evalAuthorization(
        ({ viewTypeId }) => is.nullish(viewTypeId),
        () => {
          if (user.isStudioView()) {
            return of(false);
          }

          return performer.onStatusChange$.pipe(map(() => !performer.hasMissingDataStatus()));
        }
      )
    )
  ),
};

export default authorization;
